.input-group {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}

.form-control {
    position: relative;
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 1.4rem;
    border: var(--border-grey);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 1px rgba(0,0,0,.06);
    outline: none;
}

.input-password {
    position: relative;

    &:after {
        content: '';
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        width: 36px;
        background: url('/assets/images/icon_lock.svg') no-repeat center;                 
    }

    & input {
        padding-left: 36px;
    }
}

.checkbox,
.select {
    font-size: 1.2rem;
    color: var(--grey-dark);
    margin-bottom: 16px;
}

.checkbox {
    & label {
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            width: 16px;
            height: auto;
            margin-right: 8px;

            & polygon {
                opacity: 0;
            }
        }
    }

    & input {
        display: none;
    }

    & input:checked ~ label {
        & svg {
            & polygon {
                opacity: 1;
            }
        }
    }
}

.select {
    
}