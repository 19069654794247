.modal--outdated {
    display: none;
    background-color: rgb(0,74,150);

    & img {
        margin: 40px auto;
        position: relative;
        width: 180px;
    }

    & .modal-content {
        margin: 0 auto;
        background-color: #fff;
    }

    & .modal-body {
        line-height: 1.6;
        padding: 32px 40px;
    }

    & h1 {
        color: rgb(0,74,150);
    }

    & p,
    & li {
        font-size: 1.2rem;
    }
}

html.ie {
    & .modal--outdated {
        display: block;
    }

    & #app {
        display: none;
    }
}