#login {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    opacity: 1;
    background-color: var(--blue);
    text-align: center;

    & .form--login {
        width: 100%;
        max-width: 320px;
    }

    & .form-header {
        & img {
            margin: 0 auto;
            position: relative;
            width: 180px;
        }

        & h1 {
            color: var(--white);
            margin: 20px 0 0;
        }
    }

    & .form-body {
        background-color: white;
        padding: 36px;
        border-radius: 18px;
        margin-top: 40px;
        margin-bottom: 24px;

        & p {
            color: var(--blue);
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 16px;
        }

        & .btn {
            margin-top: 16px;
        }
    }

    & .form-footer {
        &  a {
            color: var(--white);
            font-size: 1.2rem;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

}