#canvas,
#map {
    width: 100%;
    height: 100%;
}

#canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & .label,
    & .note {
        position: absolute;
        transform: translate(-50%, -50%);
        transition: opacity .2s linear;
        margin: 0;
        font-size: calc(1rem * (var(--zoom-factor)));
        white-space: nowrap;
    }

    & .note {
        opacity: 0;
        pointer-events: none;
        font-size: calc(1.2rem * (var(--zoom-factor)));
        padding: calc(0.6rem * (var(--zoom-factor))) calc(0.8rem * (var(--zoom-factor)));

        &.visible {
            opacity: 1;
            pointer-events: auto;
        }
    }

    & .label {
        opacity: 1;
        pointer-events: auto;

        &[data-type="cluster"],
        &[data-type="partner"] {
            /* margin-top: -17px; */
            transform: translate(-50%, var(--label-offset));
            border: 1px solid var(--white);
            cursor: pointer;
        }

        &[data-type="partner"] {
            display: none;
        }

        &[data-type="arrow"] {
            transition: none;
            opacity: 0;
            border-radius: calc(1.2rem * (var(--zoom-level)));
            padding: calc(0.6rem * (var(--zoom-factor))) calc(0.8rem * (var(--zoom-factor)));
        }

        &.disabled,
        &.hidden {
            opacity: 0;
            pointer-events: none;
        }

        &.visible {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

#map {
    & [data-type] {
        cursor: pointer;
    }

    & g {
        opacity: 1;
        pointer-events: auto;
        transition: opacity .2s linear, filter .2s linear;

        &.disabled {
            filter: url("#grayscale");
            opacity: .25;
            pointer-events: auto;
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    & .arrow-dot,
    & .note-dot {
        opacity: 0;
        pointer-events: none;
    }

    & .chain-group,
    & .arrow-group {
        opacity: 0;
        pointer-events: none;
        transition: none;

        &.visible {
            opacity: 1;
            pointer-events: auto;
        }
    }

    & .arrow-group {
        & .arrow {
            opacity: 0;
            pointer-events: none;
            transition: opacity .2s linear;

            &.visible {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}


/* USED FOR DEBUGGING PURPOSES */
/* 
#bounding-rectangle {
    stroke-width: 2px;
    stroke: red;
} 
*/