* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

body,
html {
	position: fixed;
	font-family: Helvetica, sans-serif;
	background-color: var(--blue);
	color: var(--grey-dark);
	overflow: hidden;
	width: 100%;
	height: 100%;
	overscroll-behavior: none;
}

html {
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	font-size: 62.5%;
}

body {
	font-size: 1.2rem;
	line-height: 1.2;
}

.hidden {
	display: none !important;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

p,
a,
li {
	text-decoration: none;
}

a {
	color: var(--blue);
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

strong {
	font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	color: var(--blue);
}

h1 {
	font-size: 2rem;
}

h2 {
	font-size: 1.8rem;
}

h3 {
	font-size: 1.6rem;
}

h4 {
	font-size: 1.4rem;
}

h5 {
	font-size: 1.2rem;
}

h6 {
	font-size: 1.1rem;
}

.text-center {
	text-align: center;
}

.logo {
	position: absolute;
	top: 0;
	left: 0;
	width: 160px;
	transform: translate(0, 0);
	z-index: 998;
}

#app {
	width: 100vw;
	min-height: 100vh;
}

#app[v-cloak],
#app.preloading {
	pointer-events: none;

	& > * {
		visibility: hidden !important;
		
	}

	& .logo {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		visibility: visible !important;
	}
}

#preloader {
	display: none;
}