/* custom media queries */
@custom-media --retina (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx);
@custom-media --narrow (width > 20em); /* 320px */
@custom-media --small (width > 30em); /* 480px */
@custom-media --medium (width > 48em); /* 768px */
@custom-media --normal (width > 64em); /* 1024px */
@custom-media --large (width > 70em); /* 1120px */
@custom-media --big (width > 87.5em); /* 1400px */

/* custom properties */
:root {
	/* colors */
	--white: rgb(255,255,255);
	--black: rgb(0,0,0);
	--blue: rgb(0,74,150);
	--blue-light: rgb(238,244,250);
	--grey-dark: rgb(74,74,74);
	--grey: rgb(242,239,236);

	--arrows-blue: rgb(33,160,210);
	--arrows-green: rgb(101,172,30);
	--arrows-orange: rgb(243,149,0);

	--overlay: rgba(0,74,150,0.5);

	/* borders */
	--border-grey: 1px solid rgb(203,203,203);
	--border-radius: 6px;

	/* dimensions */
	--zoom-level: 1;
	--zoom-factor: 1;
	--label-offset: -100%;

	--sidebar-width: 320px;
}