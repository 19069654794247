.btn,
.ctrl,
button {
    display: inline-block;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    appearance: none;
    background-color: transparent;
}

/* buttons */

.btn {
    position: relative;
    padding: 11px 34px;
    font-size: 1.3rem;
    font-weight: bold;
    border-radius: 20px;
}

.btn-primary {
    background-color: var(--blue);
    color: var(--white);
}


/* controls */

.ctrl {
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Helvetica, Arial, sans-serif;
    border: 1px solid #EEF0F0;
    border-radius: 50%;
    background-color: var(--white);
    box-shadow: 0 1px 10px rgba(0,0,0,.14);
}

.ctrl.select,
.ctrl.checkbox {
    border-radius: 20px;
    margin: 0;
}

.ctrl.select {
    border-color: transparent;

    & .v-select {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 20px;

        & .dropdown-toggle {
            height: 100%;
            border: none;
            padding: 0;
        }

        & .dropdown-menu {
            border: none;
            border-radius: 0 0 20px 20px;
            background-color: var(--white);
            box-shadow: 0 5px 10px rgba(0,0,0,.14);
            overflow-y: auto;
            max-height: initial !important;
            padding: 0;
            transition: none;

            & li {
                border-top: 1px solid #F0F4F7;
            }

            & a {
                height: 36px;
                line-height: 36px;
                padding: 0 20px;
                text-decoration: none;
                text-transform: uppercase;
            }

            & .active {
                display: none;
            }

            & .highlight {
                & a {
                    font-weight: bold;
                    background-color: var(--blue);
                    color: var(--white);
                }
            }
        }

        & .vs__selected-options {
            position: relative;

            &:before {
                content: 'Kies een waardeketen';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                padding-left: 20px;
            }
        }

        & .vs__actions {
            width: 40px;
            padding: 0;

            & .clear {
                display: none;
            }

            & i {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: center;

                &:before {
                    position: relative;
                    top: -2px;
                    left: -5px;
                    width: 8px;
                    height: 8px;
                    border-width: 2px 2px 0 0;
                }
            }
        }

        & .selected-tag {
            color: currentColor;
            margin: 0;
            padding: 0;
            opacity: 0;
            transition: none;
            padding-left: 20px;
            flex: 1 1 auto;
        }

        &.unsearchable {
            & input[type="search"] {
                position: absolute;
            }
        }

        &.open {
            & .dropdown-toggle {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: var(--white);
                    box-shadow: 0 10px 10px rgba(0,0,0,.14);
                }
            }

            & .vs__selected-options {
                &:before {
                    display: none;
                }
            }

            & .vs__actions {
                & i {
                    display: none;
                }
            }

            & .selected-tag {
                position: relative;
                opacity: 1;
                text-transform: uppercase;
            }
        }
    }

    &.select--active {
        & .v-select {
            background-color: var(--blue);
            color: var(--white);

            & .selected-tag {
                text-transform: uppercase;
                font-weight: bold;
                opacity: 1;
            }

            & .vs__selected-options {
                &:before {
                    display: none;
                }
            }

            & .vs__actions {
                & i {
                    &:before {
                        border-color: currentColor;
                    }
                }
            }

            &.open {
                background-color: inherit;
                color: inherit;

                & .selected-tag {
                    font-weight: normal;
                }
            }
        }
    }
}

.ctrl.checkbox {
   
}

.ctrl--chains,
.ctrl--all,
.ctrl--help {
    height: 40px;
    top: 16px;
}

.ctrl--help {
    left: 180px;
    width: 40px;
    height: 40px;
    text-indent: -10000em;
    background-image: url('/assets/images/icon_help.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.ctrl--chains {
    left: 235px;
    width: 230px;
}

.ctrl--all {
    left: 480px;
    width: 210px;
}

.ctrl--zoom-in,
.ctrl--zoom-out {
    left: 20px;
    width: 64px;
    height: 64px;
    text-indent: -10000em;
    background-repeat: no-repeat;
    background-position: center;
}

.ctrl--zoom-in {
    bottom: 100px;
    background-image: url('/assets/images/icon_zoom_in.svg');
}

.ctrl--zoom-out {
    bottom: 20px;
    background-image: url('/assets/images/icon_zoom_out.svg');
}