/* UTILS */
@import url("utils/reset.css");
@import url("utils/variables.css");

/* BASE */
@import url("base/common.css");

/* COMPONENTS */
@import url("components/lists.css");
@import url("components/buttons.css");
@import url("components/labels.css");
@import url("components/notes.css");
@import url("components/tooltips.css");
@import url("components/forms.css");
@import url("components/alerts.css");
@import url("components/modals.css");

/* VIEWS */
@import url("views/outdated.css");
@import url("views/login.css");
@import url("views/help.css");
@import url("views/map.css");
@import url("views/info.css");