/* panel */

.panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    filter: drop-shadow(0 0 8px rgba(0,0,0,.6));
    width: var(--sidebar-width);
    z-index: 8888;
}

.panel-close {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 70px;
    height: 70px;
    clip-path: inset(0 50% 0 0);
    background: var(--white) url('/assets/images/arrow_right.svg') no-repeat 40% center;
    border-radius: 50%;
    text-indent: -10000em;
}

.panel-header {
    position: relative;
    padding: 24px 24px 0;                      

    & h1,
    & h6 {
        display: inline-block;
        text-transform: uppercase;
    }

    & h1 {
        margin-right: 8px;
    }

    &.has-carousel {
        height: 160px;

        & section {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;

            &:first-of-type {
                pointer-events: none;
                z-index: 1;
            }
        }

        & h1,
        & h6 {
            position: absolute;
            background-color: var(--white);
        }

        & h1 {
            left: 0;
            bottom: 0;
            padding: 12px 24px 0;
            margin-right: 0;
            border-top-right-radius: var(--border-radius);
        }

        & h6 {
            top: 0;
            right: 0;
            padding: 8px 12px;
            border-bottom-left-radius: var(--border-radius);
        }

        & .VueCarousel {
            z-index: 0;
        }
    }

    & .VueCarousel {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--blue);
            opacity: .15;
            pointer-events: none;
        }
    }
 
    & .VueCarousel-wrapper,
    & .VueCarousel-inner,
    & .VueCarousel-slide {
        height: 100%;
    }

    & .VueCarousel-slide {
        & img {
            object-fit: cover;
            width: var(--sidebar-width);
            height: 160px;
        }
    }

    & .VueCarousel-pagination {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        text-align: left !important;
        padding-left: 18px;

        & .VueCarousel-dot-container {
            margin: 0;
        }

        & .VueCarousel-dot {
            margin: 0 !important;
            padding: 8px 6px !important;

            & button {
                outline: none;
                background: var(--white) !important;
                opacity: .35;
                transition: opacity .1s linear;
            }

            &.VueCarousel-dot--active {
                & button {
                    opacity: 1;
                }
            }
        }
    }
}

.panel-body {
    position: relative;
    line-height: 1.6;
    flex: 1;
    padding: 0 24px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &:before,
    &:after {
        content: '';
        display: block;
        position: sticky;
        left: 0;
        right: 0;
        z-index: 1;
        height: 24px;
        pointer-events: none;
        background-repeat: no-repeat;
    }

    &:before {
        top: 0;
        background: linear-gradient(white, rgba(255,255,255,0));
    }

    &:after {
        bottom: 0;
        background: linear-gradient(rgba(255,255,255,0), white);
    }
}

.panel-footer {
    padding: 18px 24px;
    background-color: var(--blue);
    color: var(--white);

    & .checkbox {
        color: currentColor;
        margin: 0;

        & label {
            justify-content: flex-start;
        }
    }
}

/* logo's */

.logo-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    & img {
        margin-right: 16px;
        height: 35px;
        width: auto;
    }
}


/* accordion */

.accordion {
    margin-top: 24px;
}

.accordion-item {
    padding: 15px;
    margin: 12px 0;
    background-color: var(--white);
    border: var(--border-grey);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 1px rgba(0,0,0,.06);
    transition: all .2s linear;

    &:last-child {
        margin-bottom: 0;
    }

    & .accordion-title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-transform: uppercase;
        cursor: pointer;
        
        &:after {
            content: '';
            display: block;
            position: relative;
            top: 1px;
            background: url('/assets/images/arrow_right_small.svg') no-repeat center;
            width: 6px;
            height: 10px;
            transition: transform .2s linear;
        }
    }
    
    & .accordion-content {
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: max-height .4s ease-out;

        & h6 {
            color: var(--grey-dark);
            font-size: 1rem;
            font-weight: normal;
            text-transform: uppercase;
            margin: 16px 0 0;
        }

        & p:first-child {
            margin-top: 16px;
        }
    }
}

.accordion-item--active {
    background-color: var(--blue-light);
    border-color: var(--blue-light);
    box-shadow: none;

    & .accordion-title {
        &:after {
            transform: rotate(90deg);
        }
    }

    & .accordion-content {
        height: auto;
        max-height: 1000px;
        transition: max-height .4s ease-in;
    }
}

/* transitions */

.slide-enter-active, .slide-leave-active {
    transform: translateX(0);
    transition: all .5s ease-in-out;
}
.slide-enter, .slide-leave-to {
    transform: translateX(430px);
}