.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--overlay);
    transition: all .3s ease;

    & .modal-content {
        position: relative;
        width: 100%;
        max-width: 620px;
        background-color: var(--white);
        border-radius: 18px;
        box-shadow: 0 2px 20px rgba(0,0,0,.5);
        transition: all .3s ease;
    }

    & .modal-close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 20px;
        height: 20px;
        text-indent: -10000em;
        background: url('/assets/images/icon_close.svg') no-repeat center;
    }

    & .modal-body {
        padding: 40px;
    }
}

/* transitions */

.modal-enter {
    opacity: 0;
}
  
.modal-leave-active {
    opacity: 0;
}
  
.modal-enter .modal-content,
.modal-leave-active .modal-content {
    transform: scale(1.1);
}
