.note {
    display: inline-block;
    font-size: 1.2rem;
    background-color: var(--white);
    border: 2px solid var(--blue);
    border-radius: 1rem;
    padding: .6rem 1rem;

    & strong {
        font-size: 80%;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--blue);
    }
}