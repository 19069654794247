.list {
    & li {
        position: relative;
        padding-left: 16px;
    }
}

.list--arrows {
    & li {
        &:before {
            content: '\2192';
            position: absolute;
            top: 1px;
            left: 0;
        }
    }
}