.tooltip {
    z-index: 8889;
    background-color: var(--white);
    padding: 8px 16px;
    text-align: center;
    color: rgb(203,203,203);
    border: var(--border-grey);
    border-color: currentColor;
    border-radius: 4px;
    max-width: 240px;
    top: -2px !important;
    filter: drop-shadow(0 2px 6px rgba(0,0,0,.2));

    & .tooltip-arrow {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            clip-path: polygon(50% 100%, 0 0, 100% 0);
        }

        &:before {
            width: 10px;
            height: 8px;
            background: currentColor;
        }

        &:after {
            width: 8px;
            height: 6px;
            background: var(--white);
        }
    }

    & .tooltip-inner {
        color: var(--grey-dark);
    }

    &.tooltip--blue {
        color: var(--arrows-blue);
    }

    &.tooltip--orange {
        color: var(--arrows-orange);
    }

    &.tooltip--green {
        color: var(--arrows-green);
    }
}