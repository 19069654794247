.alert {
    padding: 12px;
    border-radius: 3px;
    margin: 0 0 16px;

    & * {
        font-size: 1.2rem !important;
        font-weight: normal !important;
        color: currentColor !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    &.alert--danger {
        border: 1px solid #ecadb8;
        background-color: #f5d3d9;
        color: #d0021b;
    }
}