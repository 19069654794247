.label {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--white);
    border-radius: 1.2rem;
    padding: .6rem .8rem;
    margin: 4px 4px 0 0;

    & .badge {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        border-radius: 50%;
        padding: calc(0.2rem * var(--zoom-factor)) calc(0.5rem * var(--zoom-factor));
        left: calc(-0.4rem * var(--zoom-factor));
        margin-top: calc(-0.2rem * var(--zoom-factor));
        margin-bottom: calc(-0.2rem * var(--zoom-factor));
    }
}

.label--grey {
    background-color: var(--grey);
    color: var(--blue);

    & .badge {
        color: var(--grey);
    }
}

.label--blue {
    background-color: var(--arrows-blue);

    & .badge {
        color: var(--arrows-blue);
    }
}

.label--orange {
    background-color: var(--arrows-orange);

    & .badge {
        color: var(--arrows-orange);
    }
}

.label--green {
    background-color: var(--arrows-green);

    & .badge {
        color: var(--arrows-green);
    }
}

.label--text {
    color: var(--grey-dark);
    padding: 0;
    text-align: left;
}