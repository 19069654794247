.modal--help {
    & .modal-body {
        line-height: 1.6;
        padding: 32px 40px;
    }

    & .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 24px 0;
    }

    & h1 {
        color: var(--blue);
    }

    & p,
    & li {
        font-size: 1.2rem;
    }

    & i {
        color: var(--blue);
    }

    & img {
        margin-right: 24px;
    }

    & .blue {
        color: var(--arrows-blue);
    }
    & .green {
        color: var(--arrows-green);
    }
    & .orange {
        color: var(--arrows-orange);
    }

    & .btn {
        margin: 24px auto 0;
    }
}